import React from "react";

const Footer = () => {
  return (
    <footer className="text-center font-merriweatherReg py-6 mt-64  ">
      <p> &copy; Elyas F. 2024</p>
    </footer>
  );
};

export default Footer;
