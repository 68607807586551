const projects = [
  {
    name: "Saya Gems",
    tools: ["Shopify", "HTML", "CSS", "Figma"],
    github: "",
    website: "",
    description: "Client project to develop a Jewellery eCommerce website.",
    images: [
      "/assets/image/sayagems/saya-home.png",
      "/assets/image/sayagems/saya-about.png",
      "/assets/image/sayagems/saya-custom.png",
      "/assets/image/sayagems/saya-checkout.png",
      "/assets/image/sayagems/saya-products.png",
      "/assets/image/sayagems/saya-mobile-home.png",
      "/assets/image/sayagems/saya-mobile-about.png",
    ],
    slug: "saya",
  },
  {
    name: "Audiophile Website",
    tools: [
      "React",
      "Typescript",
      "Vite",
      "React-router",
      "Tailwind",
      "Git",
      "Github",
      "Netlify",
    ],
    description: "eCommerce website for speakers, headphones and earphones.",
    website: "https://splendorous-kheer-e419cf.netlify.app/",
    github: "https://github.com/BlueElyas/audiophile-website",
    images: [
      "/assets/image/audiophile/audiophile-home.png",
      "/assets/image/audiophile/audiophile-headphones.png",
      "/assets/image/audiophile/audiophile-cart.png",
      "/assets/image/audiophile/audiophile-checkout.png",
      "/assets/image/audiophile/audiophile-oneheadphone.png",
      "/assets/image/audiophile/audiophile-mobile-checkout.png",
      "/assets/image/audiophile/audiophile-mobile-home.png",
    ],
    slug: "audiophile",
  },
  {
    name: "Dreamlands",
    tools: ["HTML", "CSS", "Javascript", "Git", "Github", "Font Awesome"],
    description:
      "Responsive website developed in a team. Includes animation effects and functional javascript.",
    website: "https://technative-academy.github.io/Dream-Generator/",
    github: "https://github.com/BlueElyas/Dream-Generator",
    images: [
      "/assets/image/dreamland/dreamland-home.png",
      "/assets/image/dreamland/dreamland-about.png",
      "/assets/image/dreamland/dreamland-products.png",
      "/assets/image/dreamland/dreamland-mobile-home.png",
      "/assets/image/dreamland/dreamland-mobile-about.png",
    ],
    slug: "dreamlands",
  },
  {
    name: "Country Search using RESTful API",
    tools: [
      "React",
      "Javascript",
      "Tailwind",
      "Git",
      "Github",
      "Vite",
      "Font Awesome",
    ],
    github: "https://github.com/BlueElyas/country-search-restful-api",
    website: "https://loquacious-pithivier-fa024f.netlify.app/",
    description:
      "Country search and filter with country card displaying full data when clicked.",
    images: [
      "/assets/image/country-api/country-lightmode.png",
      "/assets/image/country-api/country-darkmode.png",
      "/assets/image/country-api/country-card.png",
      "/assets/image/country-api/country-mobile.png",
      "/assets/image/country-api/country-mobile-card.png",
    ],
    slug: "country",
  },
];

export default projects;
